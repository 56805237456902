<template>
  <div class="main-menu menu-fixed menu-accordion menu-shadow border-right" :class="[
    { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
    skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
  ]" @mouseenter="updateMouseHovered(true)" @mouseleave="updateMouseHovered(false)">
    <!-- main menu header-->
    <div class="navbar-header expanded my-2" style="height: 15%;">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon">
        <ul class="nav navbar-nav flex-row h-100">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <b-row align-v="start" class="my-0 px-1">
                <b-col lg="6">
                  <b-img :src="appLogoImage" class="w-100 h-100" alt="logo" />
                </b-col>
                <b-col lg="6" class="mx-0">
                  <b-row align-v="start" class="my-0">
                    <b-col lg="8">
                      <span class="font-weight-bold text-success"> {{ appName }}</span>
                    </b-col>
                    <b-col lg="6">
                      <span class="user-status font-weight-light text-success">App</span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />
    <ul ref="scrollContainer" class="scroll-content">
      <vertical-nav-menu-items :items="navMenuItems" class="navigation navigation-main" />
    </ul>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      minScrollbarLength: 1,
      wheelPropagation: true,
    }
    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },

  data() {
    return {
      navMenuItems: []
    }
  },
  mounted() {
    const buildMenu = async (menu_items, parent_id = null) => {
      const menu = [];

      for (const item of menu_items) {
        if (item.father === parent_id) {
          const menuItem = {
            title: item.name,
            route: item.path,
            icon: item.icon,
          };

          const children = await buildMenu(menu_items, item.id);
          if (children.length > 0) {
            menuItem.children = children;
          }

          menu.push(menuItem);
        }
      }

      return menu;
    }

    const fetchData = async () => {
      try {
        const response = await this.$http.get('configuration/module/index');
        this.navMenuItems = await buildMenu(response.data.data);
      } catch (error) { }
    };

    fetchData();
  },

}
</script>

<style scoped>
.scroll-content {
  height: 78%;
  overflow: auto;
  box-sizing: border-box;
  box-sizing: content-box;
  overflow-y: auto;
  scroll-behavior: smooth;
}



/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

.scroll-content::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scroll-content::-webkit-scrollbar:vertical {
  width: 10px;
}

.scroll-content::-webkit-scrollbar-button:increment,
.scroll-content::-webkit-scrollbar-button {
  display: none;
}

.scroll-content::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scroll-content::-webkit-scrollbar-thumb {
  background-color: #bababacb;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.scroll-content::-webkit-scrollbar-track {
  border-radius: 10px;
}
</style>
<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
