<template>
  <li v-if="canViewVerticalNavMenuLink(item)" class="nav-item" :class="{
    'active': isActive,
    'disabled': item.disabled
  }">
    <b-link v-bind="linkProps" class="d-flex align-items-center">
      <acta-icon v-if="item.icon == 'ActaIcon'" />
      <admi-icon v-else-if="item.icon == 'AdmiIcon'" />
      <dash-icon v-else-if="item.icon == 'DashIcon'" />
      <desp-icon v-else-if="item.icon == 'DespIcon'"> </desp-icon>
      <inve-icon v-else-if="item.icon == 'InveIcon'" />
      <orde-icon v-else-if="item.icon == 'OrdeIcon'" > </orde-icon>
      <prod-icon v-else-if="item.icon == 'ProdIcon'" />
      <repo-icon v-else-if="item.icon == 'RepoIcon'" />
      <soli-icon v-else-if="item.icon == 'SoliIcon'"> </soli-icon>
      <feather-icon v-else :icon="item.icon || 'CircleIcon'" />


      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'


export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

  mounted(){
    
  }

}
</script>
