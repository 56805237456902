<template>
  <div class="navbar-container d-flex content align-items-center pt-4 pb-0">
    <!-- Header -->
    <div class="d-sm-flex d-none user-nav w-4 px-1">
      <b-row align-v="start" class="my-0">
        <b-col lg="12">
          <p class="user-name font-weight-bolder mb-0 h1">
            {{ $route.meta.breadcrumb[0].headerTitle === '' ? getHeaderTitle : $route.meta.breadcrumb[0].headerTitle }}
          </p>
        </b-col>
        <b-col lg="12">
          <span class="user-status">{{ $route.meta.breadcrumb[0].headerSubTitle === '' ? getHeaderSubTitle :
            $route.meta.breadcrumb[0].headerSubTitle
          }}</span>
        </b-col>
      </b-row>
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <b-avatar size="40" variant="warning" class="mx-1" />
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.name }}
            </p>
            <span class="user-status">{{ user.profile.name }}</span>
          </div>
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout()">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Cerrar Sesion</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'


export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    VuePerfectScrollbar
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("current_user")).user,
      settings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      notifications: []
    };
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },

  methods: {
    logout() {
      this.$http
        .post('/logout')
        .then(response => {
          localStorage.removeItem('current_user')
          this.$http.defaults.headers.common.Authorization = ''
          this.$router.push({ path: '/login' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              text: error.response.data.message,
              title: 'Error',
              icon: 'ErrorIcon',
              variant: 'danger',
            }
          });
        })
    },
  }
}
</script>
